import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "InstarVision Surveillance Center",
  "path": "/Software/iOS/InstarVision/Wizard/iPad/Add_Camera/New_IP_Cam/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Um mit unserer Android App InstarVision auf Ihre IP-Kamera zuzugreifen, empfehlen wir Ihnen, zuerst die neueste Version aus dem App Store herunterzuladen. Verwenden Sie das Suchwort Instar, um unsere App im Geschäft zu finden.",
  "image": "./P_SearchThumb_InstarVision_Android.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Android.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Android-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "ipad-app-installationsassistent",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ipad-app-installationsassistent",
        "aria-label": "ipad app installationsassistent permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`iPad App Installationsassistent`}</h2>
    <h3 {...{
      "id": "hinzufügen-einer-neuen-ip-kamera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hinzuf%C3%BCgen-einer-neuen-ip-kamera",
        "aria-label": "hinzufügen einer neuen ip kamera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hinzufügen einer neuen IP Kamera`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a6a6ff4fea471f49d8dfb72e10c6024c/49853/iPad_Wizard_New_IP_Cam_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABaElEQVQoz32Si24jIQxF5/+/slJabdWsYcDGDzCwykwzmWS7e3UknlcW+C6f1/X6G97fLwCgqsQaSxfr3nq7AxAAwrF0H4Tl8hmXNSMzqyozN3fRGrMhWSkPYswx5vOOsEVsSwgAAPMub45ZUnoCURH1eZNz4iWlRERzzrGpNc9ZDnbbmfPpEmNExDln7303p8Q761pCwBgpBAoBN2hd+ah/MwPAuMvdRapIU23MFsK6PThtkxRCKkW3CzcWd6+1HuY+BmT9FThkVhXRQyKiZjq2r4kyL2ku80VbcXdvzX/UfsvaLDaX8azeO6pH7tr66O59133sffOPKPMt/mUefWTiNRekUkoh2rnpWHZ30nGl8WpuzYl0w/beENnOfX7rFqEUktc3u/eU5NzMf5Gy/FD5cZz4//5v85Gw7l5Q8BSvU9oesfs2m5mIAEAp3Kp9JfsIFbDW2vZgbO2tKSFiMasimjOJmFn9A9O+ZFSsEDW2AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a6a6ff4fea471f49d8dfb72e10c6024c/e4706/iPad_Wizard_New_IP_Cam_01.avif 230w", "/en/static/a6a6ff4fea471f49d8dfb72e10c6024c/d1af7/iPad_Wizard_New_IP_Cam_01.avif 460w", "/en/static/a6a6ff4fea471f49d8dfb72e10c6024c/7f308/iPad_Wizard_New_IP_Cam_01.avif 920w", "/en/static/a6a6ff4fea471f49d8dfb72e10c6024c/e1c99/iPad_Wizard_New_IP_Cam_01.avif 1380w", "/en/static/a6a6ff4fea471f49d8dfb72e10c6024c/76ea5/iPad_Wizard_New_IP_Cam_01.avif 1840w", "/en/static/a6a6ff4fea471f49d8dfb72e10c6024c/b105c/iPad_Wizard_New_IP_Cam_01.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a6a6ff4fea471f49d8dfb72e10c6024c/a0b58/iPad_Wizard_New_IP_Cam_01.webp 230w", "/en/static/a6a6ff4fea471f49d8dfb72e10c6024c/bc10c/iPad_Wizard_New_IP_Cam_01.webp 460w", "/en/static/a6a6ff4fea471f49d8dfb72e10c6024c/966d8/iPad_Wizard_New_IP_Cam_01.webp 920w", "/en/static/a6a6ff4fea471f49d8dfb72e10c6024c/445df/iPad_Wizard_New_IP_Cam_01.webp 1380w", "/en/static/a6a6ff4fea471f49d8dfb72e10c6024c/78de1/iPad_Wizard_New_IP_Cam_01.webp 1840w", "/en/static/a6a6ff4fea471f49d8dfb72e10c6024c/03b06/iPad_Wizard_New_IP_Cam_01.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a6a6ff4fea471f49d8dfb72e10c6024c/81c8e/iPad_Wizard_New_IP_Cam_01.png 230w", "/en/static/a6a6ff4fea471f49d8dfb72e10c6024c/08a84/iPad_Wizard_New_IP_Cam_01.png 460w", "/en/static/a6a6ff4fea471f49d8dfb72e10c6024c/c0255/iPad_Wizard_New_IP_Cam_01.png 920w", "/en/static/a6a6ff4fea471f49d8dfb72e10c6024c/b1001/iPad_Wizard_New_IP_Cam_01.png 1380w", "/en/static/a6a6ff4fea471f49d8dfb72e10c6024c/161ec/iPad_Wizard_New_IP_Cam_01.png 1840w", "/en/static/a6a6ff4fea471f49d8dfb72e10c6024c/49853/iPad_Wizard_New_IP_Cam_01.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a6a6ff4fea471f49d8dfb72e10c6024c/c0255/iPad_Wizard_New_IP_Cam_01.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
 
* __[01]__ Beginnen Sie mit dem Aufruf des Menüs __Kameras__ und wählen Sie die Option __Kamera hinzufügen__.
* __[02]__ Sie können nun mit dem Hinzufügen des Fernzugriffs über den [Point2Point (P2P)](/Software/iOS/InstarVision/iPhone/P2P/) oder [DDNS](/Software/iOS/InstarVision/iPhone/DDNS/) Dienst fortfahren.
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0d8cebc41200ca966f3afa0760268279/49853/iPad_Wizard_New_IP_Cam_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAz0lEQVQoz62SwYrDMAxEI7mRI1nGiWISkksg//+RCzZbukt23Za+gw6SB43MdN2NgLh7E3Tgbt1nAQBErPWp18w8fGNmOed5nnPOInLvO+eu7COGEBDRFe5ra60dIhqG4Vp8PXjAOee9b4sRsS88+myLubAsy3Ec53mu6yqFp8QxRjNLKYlIjFFVp2kysxdsv3wzMzfF1wsAQFWZWVVFhH9SRyLS9/1/qdr3nYh+mdq2zXsPAI2o1Rgxc0ppHEdVrae2lRUiCiFoIYTw1198AfBBCxfs+4/oAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0d8cebc41200ca966f3afa0760268279/e4706/iPad_Wizard_New_IP_Cam_02.avif 230w", "/en/static/0d8cebc41200ca966f3afa0760268279/d1af7/iPad_Wizard_New_IP_Cam_02.avif 460w", "/en/static/0d8cebc41200ca966f3afa0760268279/7f308/iPad_Wizard_New_IP_Cam_02.avif 920w", "/en/static/0d8cebc41200ca966f3afa0760268279/e1c99/iPad_Wizard_New_IP_Cam_02.avif 1380w", "/en/static/0d8cebc41200ca966f3afa0760268279/76ea5/iPad_Wizard_New_IP_Cam_02.avif 1840w", "/en/static/0d8cebc41200ca966f3afa0760268279/b105c/iPad_Wizard_New_IP_Cam_02.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0d8cebc41200ca966f3afa0760268279/a0b58/iPad_Wizard_New_IP_Cam_02.webp 230w", "/en/static/0d8cebc41200ca966f3afa0760268279/bc10c/iPad_Wizard_New_IP_Cam_02.webp 460w", "/en/static/0d8cebc41200ca966f3afa0760268279/966d8/iPad_Wizard_New_IP_Cam_02.webp 920w", "/en/static/0d8cebc41200ca966f3afa0760268279/445df/iPad_Wizard_New_IP_Cam_02.webp 1380w", "/en/static/0d8cebc41200ca966f3afa0760268279/78de1/iPad_Wizard_New_IP_Cam_02.webp 1840w", "/en/static/0d8cebc41200ca966f3afa0760268279/03b06/iPad_Wizard_New_IP_Cam_02.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0d8cebc41200ca966f3afa0760268279/81c8e/iPad_Wizard_New_IP_Cam_02.png 230w", "/en/static/0d8cebc41200ca966f3afa0760268279/08a84/iPad_Wizard_New_IP_Cam_02.png 460w", "/en/static/0d8cebc41200ca966f3afa0760268279/c0255/iPad_Wizard_New_IP_Cam_02.png 920w", "/en/static/0d8cebc41200ca966f3afa0760268279/b1001/iPad_Wizard_New_IP_Cam_02.png 1380w", "/en/static/0d8cebc41200ca966f3afa0760268279/161ec/iPad_Wizard_New_IP_Cam_02.png 1840w", "/en/static/0d8cebc41200ca966f3afa0760268279/49853/iPad_Wizard_New_IP_Cam_02.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0d8cebc41200ca966f3afa0760268279/c0255/iPad_Wizard_New_IP_Cam_02.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[03]`}</strong>{` Verwenden Sie die App, um den QR-Code von Ihrer Kamera oder Ihrem Kamerapaket zu scannen. Wenn Ihre Kamera keinen QR-Code hat, wählen Sie bitte im nächsten Schritt das Kameramodell manuell aus.`}</li>
      <li parentName="ul"><strong parentName="li">{`[04]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/66bc1cb7b6074f1112f651de7d9c1f61/49853/iPad_Wizard_New_IP_Cam_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABHElEQVQoz52P6WrDMBCE9f6PV2jBpGDHcUpSe3Vfq7OkwiYkP+L2Y0HssKNhyGXm8wLTdAIA7702jtvETEQMDUS8Xq/zPIeVGKOUclkWwqWy1nrvrbUpJW3s2/vho+uHYRqG0zCc+n7sukPXfba1zfE4jeOZUAoAUFdijF/nM2dMKS2laqO10dps6zZECKG1rrWWFaVUjLHugLBbiKq15pxLKTlnSmkzl1fczACw7TlnznlKqeyApJRCCPcSpdRauyv5uQnnPISwq/PDZzlnKWXr/zr52SyE2Nv5WWKMIeI/O1NKnXPN/LfOpRQA2JvcnvtTzmhYzZveoh6OCSI65wDAGBsDfgscF7tIH2JkvzjnEFEIoZRqx1LKJv4ArL9tkj3kwNMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/66bc1cb7b6074f1112f651de7d9c1f61/e4706/iPad_Wizard_New_IP_Cam_03.avif 230w", "/en/static/66bc1cb7b6074f1112f651de7d9c1f61/d1af7/iPad_Wizard_New_IP_Cam_03.avif 460w", "/en/static/66bc1cb7b6074f1112f651de7d9c1f61/7f308/iPad_Wizard_New_IP_Cam_03.avif 920w", "/en/static/66bc1cb7b6074f1112f651de7d9c1f61/e1c99/iPad_Wizard_New_IP_Cam_03.avif 1380w", "/en/static/66bc1cb7b6074f1112f651de7d9c1f61/76ea5/iPad_Wizard_New_IP_Cam_03.avif 1840w", "/en/static/66bc1cb7b6074f1112f651de7d9c1f61/b105c/iPad_Wizard_New_IP_Cam_03.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/66bc1cb7b6074f1112f651de7d9c1f61/a0b58/iPad_Wizard_New_IP_Cam_03.webp 230w", "/en/static/66bc1cb7b6074f1112f651de7d9c1f61/bc10c/iPad_Wizard_New_IP_Cam_03.webp 460w", "/en/static/66bc1cb7b6074f1112f651de7d9c1f61/966d8/iPad_Wizard_New_IP_Cam_03.webp 920w", "/en/static/66bc1cb7b6074f1112f651de7d9c1f61/445df/iPad_Wizard_New_IP_Cam_03.webp 1380w", "/en/static/66bc1cb7b6074f1112f651de7d9c1f61/78de1/iPad_Wizard_New_IP_Cam_03.webp 1840w", "/en/static/66bc1cb7b6074f1112f651de7d9c1f61/03b06/iPad_Wizard_New_IP_Cam_03.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/66bc1cb7b6074f1112f651de7d9c1f61/81c8e/iPad_Wizard_New_IP_Cam_03.png 230w", "/en/static/66bc1cb7b6074f1112f651de7d9c1f61/08a84/iPad_Wizard_New_IP_Cam_03.png 460w", "/en/static/66bc1cb7b6074f1112f651de7d9c1f61/c0255/iPad_Wizard_New_IP_Cam_03.png 920w", "/en/static/66bc1cb7b6074f1112f651de7d9c1f61/b1001/iPad_Wizard_New_IP_Cam_03.png 1380w", "/en/static/66bc1cb7b6074f1112f651de7d9c1f61/161ec/iPad_Wizard_New_IP_Cam_03.png 1840w", "/en/static/66bc1cb7b6074f1112f651de7d9c1f61/49853/iPad_Wizard_New_IP_Cam_03.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/66bc1cb7b6074f1112f651de7d9c1f61/c0255/iPad_Wizard_New_IP_Cam_03.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[05]`}</strong>{` Wählen Sie Ihren Kamerahersteller, z.B. `}<strong parentName="li">{`INSTAR`}</strong></li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3d6592b2a63df3f5aafdb041a331948c/49853/iPad_Wizard_New_IP_Cam_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABCklEQVQoz5WT247DIAxE8/9/udo+VCljbglgMLBqUbNdpd205w3Lg+XReDpfzEXhdPoGkFLya4Rn+LJGjjGEEJkZABExc7yRUjLGzPM8GedDCCmlEEIpkjgrcucLeb+KSM5ZRIZGRModEam1TkQA0O+01ghKayql9Ne0G5O1dlmW7V1r1Tdyzu2ISWvtve+911qHGMAHYgDbe5tcSjkWD1ceS0opAMx8LN47QUTOORHpR0z7/wAopZh5c/Hl5H1Ja030nmFPJ79r2H5nY4y19v+QPN+51kpExpgPdt76emtGk/dXt//UH1L5G89xKwDWNZTMs+WveZn1yswjLTFeD8ta670fzc65UfwBcUBxAOy9FcoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3d6592b2a63df3f5aafdb041a331948c/e4706/iPad_Wizard_New_IP_Cam_05.avif 230w", "/en/static/3d6592b2a63df3f5aafdb041a331948c/d1af7/iPad_Wizard_New_IP_Cam_05.avif 460w", "/en/static/3d6592b2a63df3f5aafdb041a331948c/7f308/iPad_Wizard_New_IP_Cam_05.avif 920w", "/en/static/3d6592b2a63df3f5aafdb041a331948c/e1c99/iPad_Wizard_New_IP_Cam_05.avif 1380w", "/en/static/3d6592b2a63df3f5aafdb041a331948c/76ea5/iPad_Wizard_New_IP_Cam_05.avif 1840w", "/en/static/3d6592b2a63df3f5aafdb041a331948c/b105c/iPad_Wizard_New_IP_Cam_05.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3d6592b2a63df3f5aafdb041a331948c/a0b58/iPad_Wizard_New_IP_Cam_05.webp 230w", "/en/static/3d6592b2a63df3f5aafdb041a331948c/bc10c/iPad_Wizard_New_IP_Cam_05.webp 460w", "/en/static/3d6592b2a63df3f5aafdb041a331948c/966d8/iPad_Wizard_New_IP_Cam_05.webp 920w", "/en/static/3d6592b2a63df3f5aafdb041a331948c/445df/iPad_Wizard_New_IP_Cam_05.webp 1380w", "/en/static/3d6592b2a63df3f5aafdb041a331948c/78de1/iPad_Wizard_New_IP_Cam_05.webp 1840w", "/en/static/3d6592b2a63df3f5aafdb041a331948c/03b06/iPad_Wizard_New_IP_Cam_05.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3d6592b2a63df3f5aafdb041a331948c/81c8e/iPad_Wizard_New_IP_Cam_05.png 230w", "/en/static/3d6592b2a63df3f5aafdb041a331948c/08a84/iPad_Wizard_New_IP_Cam_05.png 460w", "/en/static/3d6592b2a63df3f5aafdb041a331948c/c0255/iPad_Wizard_New_IP_Cam_05.png 920w", "/en/static/3d6592b2a63df3f5aafdb041a331948c/b1001/iPad_Wizard_New_IP_Cam_05.png 1380w", "/en/static/3d6592b2a63df3f5aafdb041a331948c/161ec/iPad_Wizard_New_IP_Cam_05.png 1840w", "/en/static/3d6592b2a63df3f5aafdb041a331948c/49853/iPad_Wizard_New_IP_Cam_05.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3d6592b2a63df3f5aafdb041a331948c/c0255/iPad_Wizard_New_IP_Cam_05.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[06]`}</strong>{` Wählen Sie Ihr Kameramodell, z.B. `}<strong parentName="li">{`IN-8015FullHD`}</strong></li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/576de8054fb814e6648fe16a702a78c9/49853/iPad_Wizard_New_IP_Cam_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABI0lEQVQoz62Ty27EMAhF8/8/2UUzUpPU47cN2OAqcRN5upi2as8CiWuuAMlMb5vZ3tU8vyqlAMDHvJrsQkb4pJSitTbGEFFXENEa83JbJ+N8SgkAUkqlVEDKJJWlHBBRCKGbY4zlhGtNyNP9rpRS7USYnTXWWhHpCh4QESK2NhSKTNbaEMKeHTDztm3LshBRPeCBesK1ZqyT1tp731pj5m5elmWeZwDo6TVCTzvCDMS7WSklJ8zct7oUgBxjiDGkGGWAWaZaKxGN5mGxVrlFIBuyTxASVhkf29QeuYbsQGkOm4Xmj5jLo1ke+dL5Od+Y5YwypP/U+fnOfxr7R+brh/2uMyLmnJVSMaZCuFq8abx7INqPSWudc97PyFrvfS92znXxAxQnc5w7qyLZAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/576de8054fb814e6648fe16a702a78c9/e4706/iPad_Wizard_New_IP_Cam_06.avif 230w", "/en/static/576de8054fb814e6648fe16a702a78c9/d1af7/iPad_Wizard_New_IP_Cam_06.avif 460w", "/en/static/576de8054fb814e6648fe16a702a78c9/7f308/iPad_Wizard_New_IP_Cam_06.avif 920w", "/en/static/576de8054fb814e6648fe16a702a78c9/e1c99/iPad_Wizard_New_IP_Cam_06.avif 1380w", "/en/static/576de8054fb814e6648fe16a702a78c9/76ea5/iPad_Wizard_New_IP_Cam_06.avif 1840w", "/en/static/576de8054fb814e6648fe16a702a78c9/b105c/iPad_Wizard_New_IP_Cam_06.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/576de8054fb814e6648fe16a702a78c9/a0b58/iPad_Wizard_New_IP_Cam_06.webp 230w", "/en/static/576de8054fb814e6648fe16a702a78c9/bc10c/iPad_Wizard_New_IP_Cam_06.webp 460w", "/en/static/576de8054fb814e6648fe16a702a78c9/966d8/iPad_Wizard_New_IP_Cam_06.webp 920w", "/en/static/576de8054fb814e6648fe16a702a78c9/445df/iPad_Wizard_New_IP_Cam_06.webp 1380w", "/en/static/576de8054fb814e6648fe16a702a78c9/78de1/iPad_Wizard_New_IP_Cam_06.webp 1840w", "/en/static/576de8054fb814e6648fe16a702a78c9/03b06/iPad_Wizard_New_IP_Cam_06.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/576de8054fb814e6648fe16a702a78c9/81c8e/iPad_Wizard_New_IP_Cam_06.png 230w", "/en/static/576de8054fb814e6648fe16a702a78c9/08a84/iPad_Wizard_New_IP_Cam_06.png 460w", "/en/static/576de8054fb814e6648fe16a702a78c9/c0255/iPad_Wizard_New_IP_Cam_06.png 920w", "/en/static/576de8054fb814e6648fe16a702a78c9/b1001/iPad_Wizard_New_IP_Cam_06.png 1380w", "/en/static/576de8054fb814e6648fe16a702a78c9/161ec/iPad_Wizard_New_IP_Cam_06.png 1840w", "/en/static/576de8054fb814e6648fe16a702a78c9/49853/iPad_Wizard_New_IP_Cam_06.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/576de8054fb814e6648fe16a702a78c9/c0255/iPad_Wizard_New_IP_Cam_06.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[07]`}</strong>{` Sobald Ihre Kamera angeschlossen ist, klicken Sie auf `}<strong parentName="li">{`Suchen`}</strong>{` und die App wird alle INSTAR-Kameras in Ihrem lokalen Netzwerk erkennen. Wenn Ihre Kamera nicht angezeigt wird, wählen Sie `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/DDNS/"
        }}>{`manuell hinzufügen`}</a>{`.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/77778eac52ce43593cd8b5396ab5d8a1/49853/iPad_Wizard_New_IP_Cam_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABYUlEQVQoz5WSSU5jMRCG3x1ZIkXiSkgcgUUr9DlgRavpAyAUJpF2PMY12a7We07C0GH65EWpVP9fZbuGx6Vb/jW3twtjDCLGNSw9hgQIIzlnIrLWOueIKOcMAIjonL2+expciDlnRMw5ixQkTiDEIiKl1taaiIQQvPellNZarZVFailrqsNqZYwxuqW1totzzmFiNeG9DyEg4q5g8N6nlLqsG1tre0ZEiEi2MDMRlVKexdbaGKOq1mnIUopzjpn1Q3rxKDbGtC21Vudct/uUoZTCzC/Fb2b7qPObbGsNJ0RkLFD9/6gqszDL0F5TawWAlBJB0irvde4rsEfcP2vh9de9/n4Yz59HvXrYxJf3euO2d35PfHyuB6c6m+vhDz36qUdnYzCbj8mTi81C7Lmzfpk9nTcuo5HufbCd/0a827DvdSYiADDGrNdZmJ483VlaRWQWOwEAROS9jzH24hBCT/4DloloZX+DiGIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/77778eac52ce43593cd8b5396ab5d8a1/e4706/iPad_Wizard_New_IP_Cam_07.avif 230w", "/en/static/77778eac52ce43593cd8b5396ab5d8a1/d1af7/iPad_Wizard_New_IP_Cam_07.avif 460w", "/en/static/77778eac52ce43593cd8b5396ab5d8a1/7f308/iPad_Wizard_New_IP_Cam_07.avif 920w", "/en/static/77778eac52ce43593cd8b5396ab5d8a1/e1c99/iPad_Wizard_New_IP_Cam_07.avif 1380w", "/en/static/77778eac52ce43593cd8b5396ab5d8a1/76ea5/iPad_Wizard_New_IP_Cam_07.avif 1840w", "/en/static/77778eac52ce43593cd8b5396ab5d8a1/b105c/iPad_Wizard_New_IP_Cam_07.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/77778eac52ce43593cd8b5396ab5d8a1/a0b58/iPad_Wizard_New_IP_Cam_07.webp 230w", "/en/static/77778eac52ce43593cd8b5396ab5d8a1/bc10c/iPad_Wizard_New_IP_Cam_07.webp 460w", "/en/static/77778eac52ce43593cd8b5396ab5d8a1/966d8/iPad_Wizard_New_IP_Cam_07.webp 920w", "/en/static/77778eac52ce43593cd8b5396ab5d8a1/445df/iPad_Wizard_New_IP_Cam_07.webp 1380w", "/en/static/77778eac52ce43593cd8b5396ab5d8a1/78de1/iPad_Wizard_New_IP_Cam_07.webp 1840w", "/en/static/77778eac52ce43593cd8b5396ab5d8a1/03b06/iPad_Wizard_New_IP_Cam_07.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/77778eac52ce43593cd8b5396ab5d8a1/81c8e/iPad_Wizard_New_IP_Cam_07.png 230w", "/en/static/77778eac52ce43593cd8b5396ab5d8a1/08a84/iPad_Wizard_New_IP_Cam_07.png 460w", "/en/static/77778eac52ce43593cd8b5396ab5d8a1/c0255/iPad_Wizard_New_IP_Cam_07.png 920w", "/en/static/77778eac52ce43593cd8b5396ab5d8a1/b1001/iPad_Wizard_New_IP_Cam_07.png 1380w", "/en/static/77778eac52ce43593cd8b5396ab5d8a1/161ec/iPad_Wizard_New_IP_Cam_07.png 1840w", "/en/static/77778eac52ce43593cd8b5396ab5d8a1/49853/iPad_Wizard_New_IP_Cam_07.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/77778eac52ce43593cd8b5396ab5d8a1/c0255/iPad_Wizard_New_IP_Cam_07.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[08]`}</strong>{` Sie werden nun aufgefordert, den Admin-Login Ihrer Kamera einzugeben. Wenn Sie es vorher nicht eingerichtet haben, wird dies der Standard-Userermane `}<strong parentName="li">{`admin`}</strong>{` und das Passwort `}<strong parentName="li">{`instar`}</strong>{` sein. Dies kann später innerhalb der App geändert werden.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ca71ec208e4e7610637404240c126696/49853/iPad_Wizard_New_IP_Cam_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABRElEQVQoz7WT3UrDMBSA+/J6I2PeTB9Eb8QLBTfY3mCg4IUbSNc0PU3b89MkR9quA1FxQ/wIh+QkX5IDSfK+K3aZ2WzejDFE5GrcAboaCfcws7W2KAoRGTJEVBT2dZsmRemapiGipmna1hNLhSKtjz3ee0R0PUQUQogxdjGEmkOS58YYoyMxBFVl5kEAgCzL8pEhAwAioqoJAFRV1Wk9IQQASNPUVTVS2yAjt8gtSReRpEZGYu99J1trnXOqerhSnudZtosx6M/EGPeyMSaO9Ft00zdrPXvQyVwvnvRyodO+M5nr+aPergdfE++9iHyV7551utCrlc6Wer3q2mzZDacLvX8Z5W/vcyRJ/EwI+1JDVP9DC/E3+U8nHyX/S80nyIcXdtrJzIyIxpi6blrhFHhrOXck0tqe4WMBgHNuWFyW5ZD8AOOdZ66dgYlEAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ca71ec208e4e7610637404240c126696/e4706/iPad_Wizard_New_IP_Cam_08.avif 230w", "/en/static/ca71ec208e4e7610637404240c126696/d1af7/iPad_Wizard_New_IP_Cam_08.avif 460w", "/en/static/ca71ec208e4e7610637404240c126696/7f308/iPad_Wizard_New_IP_Cam_08.avif 920w", "/en/static/ca71ec208e4e7610637404240c126696/e1c99/iPad_Wizard_New_IP_Cam_08.avif 1380w", "/en/static/ca71ec208e4e7610637404240c126696/76ea5/iPad_Wizard_New_IP_Cam_08.avif 1840w", "/en/static/ca71ec208e4e7610637404240c126696/b105c/iPad_Wizard_New_IP_Cam_08.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ca71ec208e4e7610637404240c126696/a0b58/iPad_Wizard_New_IP_Cam_08.webp 230w", "/en/static/ca71ec208e4e7610637404240c126696/bc10c/iPad_Wizard_New_IP_Cam_08.webp 460w", "/en/static/ca71ec208e4e7610637404240c126696/966d8/iPad_Wizard_New_IP_Cam_08.webp 920w", "/en/static/ca71ec208e4e7610637404240c126696/445df/iPad_Wizard_New_IP_Cam_08.webp 1380w", "/en/static/ca71ec208e4e7610637404240c126696/78de1/iPad_Wizard_New_IP_Cam_08.webp 1840w", "/en/static/ca71ec208e4e7610637404240c126696/03b06/iPad_Wizard_New_IP_Cam_08.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ca71ec208e4e7610637404240c126696/81c8e/iPad_Wizard_New_IP_Cam_08.png 230w", "/en/static/ca71ec208e4e7610637404240c126696/08a84/iPad_Wizard_New_IP_Cam_08.png 460w", "/en/static/ca71ec208e4e7610637404240c126696/c0255/iPad_Wizard_New_IP_Cam_08.png 920w", "/en/static/ca71ec208e4e7610637404240c126696/b1001/iPad_Wizard_New_IP_Cam_08.png 1380w", "/en/static/ca71ec208e4e7610637404240c126696/161ec/iPad_Wizard_New_IP_Cam_08.png 1840w", "/en/static/ca71ec208e4e7610637404240c126696/49853/iPad_Wizard_New_IP_Cam_08.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ca71ec208e4e7610637404240c126696/c0255/iPad_Wizard_New_IP_Cam_08.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[09]`}</strong>{` Bitte wählen Sie einen Namen für Ihre Kamera.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      